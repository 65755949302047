import { ModelInfo } from 'types/nn-types/ModelInfo';
import {
    checkpointInfosDecoder,
    dataArrayDecoder,
    imodelGraphDecoder,
    statisticalDescriptorsCatalogDecoder,
    modelInfoDecoder,
    modelSourceCodeDecoder,
    modelStatsDecoder,
    stringArrayDecoder,
} from 'tools/json-decoders';
import { IModelGraph } from 'types/nn-types/ModelGraph';
import npyjs from 'npyjs';
import { CheckpointInfo } from 'types/nn-types/CheckpointInfo';
import { ModelStats } from 'types/nn-types/ModelStats';
import ModelSourceCode from 'types/nn-types/ModelSourceCode';
import { DataArray } from 'types/inspection-types/DataArray';
import { TransformSpec } from 'types/inspection-types/TransformSpec';
import { StatisticalDescriptorsCatalog } from 'types/nn-types/StatisticalDescriptors';

enum ResponseType {
    JSON,
    BLOB,
    TEXT,
}

// See: https://www.typescriptlang.org/docs/handbook/release-notes/overview.html#more-recursive-type-aliases
type JSONType = string | number | boolean | null | { [property: string]: JSONType } | JSONType[];

class BackendQueryEngine {
    // THE FOLLOWING LINE IS AUTOMATICALLY CHANGED IN DOCKER DEPLOYMENT CONFIGURATION
    private static readonly BASE_URL = 'https://study-uc2-backend.innspector.dbvis.de';

    private static readonly NPYJS_PARSER: npyjs = new npyjs(); // eslint-disable-line new-cap

    private static async queryBackend(
        route: string,
        method: 'GET' | 'POST' = 'POST',
        parameters: JSONType = {},
        responseType: ResponseType = ResponseType.JSON
    ): Promise<unknown> {
        const requestURL = `${BackendQueryEngine.BASE_URL}/${route}`;

        return fetch(requestURL, {
            body: method === 'POST' ? JSON.stringify(parameters) : undefined,
            headers: {
                'Content-Type': 'application/json',
            },
            method: method,
        }).then((response) => {
            if (responseType === ResponseType.JSON) return response.json();
            if (responseType === ResponseType.BLOB) return response.blob();
            if (responseType === ResponseType.TEXT) return response.text();

            return null;
        });
    }

    public static async getAvailableModelIds(): Promise<string[]> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend('model-ids', 'GET');
        return stringArrayDecoder.decodeToPromise(jsonResponse);
    }

    public static async getModelInfo(modelId: string): Promise<ModelInfo> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`model/${modelId}`, 'GET');
        return modelInfoDecoder.decodeToPromise(jsonResponse);
    }

    public static async getModelGraph(modelId: string): Promise<IModelGraph> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`model/${modelId}/graph`, 'GET');
        return imodelGraphDecoder(modelId).decodeToPromise(jsonResponse);
    }

    public static async getModelStats(modelId: string): Promise<ModelStats> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`model/${modelId}/stats`, 'GET');
        return modelStatsDecoder.decodeToPromise(jsonResponse);
    }

    public static async getModelSourceCode(modelId: string): Promise<ModelSourceCode> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`model/${modelId}/sources`, 'GET');
        return modelSourceCodeDecoder.decodeToPromise(jsonResponse);
    }

    public static async getCheckpointInfo(modelId: string): Promise<CheckpointInfo[]> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`model/${modelId}/checkpoint-info`, 'GET');
        return checkpointInfosDecoder.decodeToPromise(jsonResponse);
    }

    public static async getCheckpointLayers(modelId: string): Promise<string[]> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(
            `model/${modelId}/checkpoints/layers`,
            'GET'
        );
        return stringArrayDecoder.decodeToPromise(jsonResponse);
    }

    public static async getSamplesAndActivations(
        modelId: string,
        step: number,
        items: Array<
            'index' | 'x' | 'y_label' | 'y_label_categorical' | 'y_prediction' | 'y_prediction_categorical' | string
        >,
        transformspec?: TransformSpec
    ): Promise<DataArray> {
        try {
            const body = {
                step,
                items,
                transformspec: transformspec ?? null,
            };

            const jsonResponse: unknown = await BackendQueryEngine.queryBackend(
                `model/${modelId}/checkpoints/samples-and-activations`,
                'POST',
                body
            );

            return dataArrayDecoder.decodeToPromise(jsonResponse);
        } catch (e) {
            console.warn('Error while fetching samples and activations from backend. Returning empty data array.');
            return [];
        }
    }

    public static async getInterestingness(modelId: string, step?: number): Promise<StatisticalDescriptorsCatalog> {
        try {
            const jsonResponse: unknown = await BackendQueryEngine.queryBackend(
                `model/${modelId}/interestingness`,
                'POST',
                step ? { step } : undefined
            );
            return statisticalDescriptorsCatalogDecoder.decodeToPromise(jsonResponse);
        } catch (e) {
            console.warn('Error while fetching interestingness from backend. Returning empty object.');
            return {};
        }
    }

    public static async getMeanActivations(
        modelId: string,
        step: number,
        layerName: string,
        classes: Array<number | string>,
        transformspec?: TransformSpec
    ): Promise<DataArray> {
        try {
            const body = {
                step,
                layer: layerName,
                classes,
                transformspec: transformspec ?? null,
            };

            const jsonResponse: unknown = await BackendQueryEngine.queryBackend(
                `model/${modelId}/checkpoints/mean-activations`,
                'POST',
                body
            );

            return dataArrayDecoder.decodeToPromise(jsonResponse);
        } catch (e) {
            console.warn('Error while fetching mean activations from backend. Returning empty data array.');
            return [];
        }
    }

    public static async getWeightsSubset(
        modelId: string,
        step: number,
        layerName: string,
        type: 'kernel' | 'bias',
        count: number,
        operation: 'head' | 'tail' = 'head'
    ): Promise<DataArray> {
        try {
            const body = {
                step,
                layer: layerName,
                type,
                count,
                operation,
            };

            const jsonResponse: unknown = await BackendQueryEngine.queryBackend(
                `model/${modelId}/checkpoints/weights-subset`,
                'POST',
                body
            );

            return dataArrayDecoder.decodeToPromise(jsonResponse);
        } catch (e) {
            console.warn('Error while fetching weights subset from backend. Returning empty data array.');
            return [];
        }
    }

    public static async getWeights(
        modelId: string,
        step: number,
        layer: string,
        type: 'kernel' | 'bias'
    ): Promise<npyjs.NumpyArray> {
        try {
            const body = {
                step,
                layer,
                type,
            };

            const blobResponse: unknown = await BackendQueryEngine.queryBackend(
                `model/${modelId}/checkpoints/weights`,
                'POST',
                body,
                ResponseType.BLOB
            );

            if (blobResponse instanceof Blob) {
                return new Response(blobResponse).arrayBuffer().then((arrayBuffer) => {
                    return BackendQueryEngine.NPYJS_PARSER.parse(arrayBuffer);
                });
            }

            return Promise.reject<npyjs.NumpyArray>(new Error('Received response is not a BLOB.'));
        } catch (e) {
            console.warn('Error while fetching weights from backend. Returning empty numpy array.');
            return BackendQueryEngine.NPYJS_PARSER.parse(new ArrayBuffer(0));
        }
    }
}

export default BackendQueryEngine;
