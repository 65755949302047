import React, { FunctionComponent } from 'react';
import Joyride, { Step } from 'react-joyride';
// import { CgDebug } from 'react-icons/cg';
// import { AiOutlineTool } from 'react-icons/ai';
// import { FaHighlighter, FaMousePointer } from 'react-icons/fa';
// import { BsEmojiSunglasses, BsLayers } from 'react-icons/bs';

interface Props {}

const JOYRIDE_STEPS: Step[] = [
    // {
    //     content: (
    //         <>
    //             <h2>Welcome to iNNspector!</h2>
    //             The system for systematic model debugging&nbsp;
    //             <CgDebug />
    //         </>
    //     ),
    //     locale: { skip: <strong aria-label="skip">Skip Tutorial</strong> },
    //     placement: 'center',
    //     target: 'body',
    // },
    // {
    //     content: (
    //         <>
    //             <h2>Toolbox</h2>
    //             Apply tools from the toolbox to model groups,
    //             <br />
    //             models, layers, or variables&nbsp;
    //             <AiOutlineTool />
    //         </>
    //     ),
    //     placement: 'left',
    //     target: '#toolbox-panel',
    // },
    // {
    //     content: (
    //         <>
    //             <h2>Architecture View</h2>
    //             Central is a display of the model architecture.
    //             <br />
    //             Currently, it shows multiple models,
    //             <br />
    //             all part of the same experiment.
    //         </>
    //     ),
    //     placement: 'right',
    //     target: '#l3-group-component-0-hull',
    // },
    // {
    //     content: (
    //         <>
    //             <h2>Architectural Entity</h2>
    //             Apply tools&nbsp;
    //             <AiOutlineTool />,
    //             <br />
    //             Double-click to descend to lower
    //             <br />
    //             levels of abstraction&nbsp;
    //             <BsLayers />,
    //             <br />
    //             or <code>[Ctrl]</code> + click to select entities&nbsp;
    //             <FaMousePointer />
    //         </>
    //     ),
    //     placement: 'right',
    //     target: '#l3-model-component-0',
    // },
    // {
    //     content: (
    //         <>
    //             <h2>Highlight Entity Types</h2>
    //             Choose entity types of interest. They are highlighted in the architecture view.
    //             <FaHighlighter />
    //             <br style={{ marginBottom: '0.25em' }} />
    //             <b>
    //                 This might be useful for the first task. <BsEmojiSunglasses />
    //             </b>
    //         </>
    //     ),
    //
    //     placement: 'right',
    //     target: '#settings-panel',
    // },
];

const JoyrideOnboarding: FunctionComponent<Props> = ({}: Props) => {
    // const isFirstLoad = useFirstLoad();

    return (
        <>
            <Joyride
                continuous
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                locale={{
                    last: 'Finish Tutorial',
                }}
                steps={JOYRIDE_STEPS}
                // run={isFirstLoad}
                run={true}
            />
        </>
    );
};

export default JoyrideOnboarding;
